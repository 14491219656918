"use client"

import React, { useState, useRef, useEffect, useContext, useMemo } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "components/ui/dialog"
import { Button } from "components/ui/button"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Textarea } from "components/ui/textarea"
import { X, Plus, Minus } from 'lucide-react'
import { toast } from "sonner"
import { AppContext } from "contexts/AppContext";
import { Email } from "@mui/icons-material"

// Sample email suggestions (replace with your actual data source)
const emailSuggestions = [
  "john@example.com",
  "jane@example.com",
  "alice@example.com",
  "bob@example.com",
]

export default function SendEmailDialog({ isOpen, onClose, emailContent }) {
  const { logEvent, sendEmail  } = useContext(AppContext);

  const [errorMessage, setErrorMessage] = useState('');

  const [to, setTo] = useState("")
  const [cc, setCc] = useState("")
  const [bcc, setBcc] = useState("")
  const [subject, setSubject] = useState("")

  const [attachments, setAttachments] = useState([])
  const [showCcBcc, setShowCcBcc] = useState(false)
  const [suggestions, setSuggestions] = useState({ to: [], cc: [], bcc: [] })
  const [cursorPosition, setCursorPosition] = useState({ to: 0, cc: 0, bcc: 0 })
  const inputRefs = useRef({ to: null, cc: null, bcc: null })
  const suggestionsRef = useRef({ to: null, cc: null, bcc: null })

  const getEmailBeforeCursor = (value, position, type) => {
    const textBeforeCursor = value.substring(0, position)
    const emails = textBeforeCursor.split(/[,\s]+/)
    const currentEmail = emails[emails.length - 1]
    return currentEmail.trim()
  }

  const handleEmailChange = (type, value, cursorPos) => {
    const stateUpdater = type === 'to' ? setTo : type === 'cc' ? setCc : setBcc
    stateUpdater(value)
    setCursorPosition(prev => ({ ...prev, [type]: cursorPos }))

    const currentEmail = getEmailBeforeCursor(value, cursorPos, type)
    
    if (currentEmail) {
      const filteredSuggestions = emailSuggestions.filter(email => 
        email.toLowerCase().includes(currentEmail.toLowerCase()) &&
        email.toLowerCase() !== currentEmail.toLowerCase()
      )
      setSuggestions(prev => ({ ...prev, [type]: filteredSuggestions }))
    } else {
      setSuggestions(prev => ({ ...prev, [type]: [] }))
    }
  }

  const handleSuggestionClick = (type, suggestion) => {
    const input = inputRefs.current[type]
    const value = type === 'to' ? to : type === 'cc' ? cc : bcc
    const position = cursorPosition[type]

    // Find the start and end of the current email
    const textBeforeCursor = value.substring(0, position)
    const textAfterCursor = value.substring(position)
    
    const beforeLastEmail = textBeforeCursor.substring(0, textBeforeCursor.lastIndexOf(getEmailBeforeCursor(value, position, type)))
    const afterCurrentEmail = textAfterCursor.substring(textAfterCursor.search(/[,\s]/) !== -1 ? textAfterCursor.search(/[,\s]/) : textAfterCursor.length)

    // Construct the new value
    const newValue = beforeLastEmail + suggestion + ', ' + afterCurrentEmail

    // Update the input value
    const stateUpdater = type === 'to' ? setTo : type === 'cc' ? setCc : setBcc
    stateUpdater(newValue)
    setSuggestions(prev => ({ ...prev, [type]: [] }))

    // Set focus back to input
    if (input) {
      input.focus()
      const newCursorPos = beforeLastEmail.length + suggestion.length + 2
      input.setSelectionRange(newCursorPos, newCursorPos)
      setCursorPosition(prev => ({ ...prev, [type]: newCursorPos }))
    }
  }

  const handleSend = async () => {
    const parseEmails = (input) => {
      const regex = /([^,\s]+@[^,\s]+\.[^,\s]+)/g;
      return Array.from(input.matchAll(regex), (match) => match[1]);
    };
  
    const toEmails = parseEmails(to);
    const ccEmails = parseEmails(cc);
    const bccEmails = parseEmails(bcc);

    const missingFields = [];
    if (!to) missingFields.push('email address');
    if (!subject) missingFields.push('subject');
    if (!emailContent.innerHTML) missingFields.push('email content');

    if (missingFields.length > 0) {
      setErrorMessage(`Please add ${missingFields.join(', ')}`);
      return;
    }
    setErrorMessage('');

    // Prepare attachments in the required format
    const formattedAttachments = attachments.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (e) => {
          const binaryString = e.target.result; // Read file as binary string
          const base64String = btoa(binaryString); // Convert to Base64
    
          // Log Base64-encoded string and its type
          console.log(`Base64 content type: ${typeof base64String}`); // Should log "string"
          console.log(`Base64 content length: ${base64String.length}`);
          console.log(`Base64 preview: ${base64String.slice(0, 50)}...`); // Log a preview of the Base64 string
    
          resolve({
            file_content: base64String, // Base64-encoded binary string
            file_name: file.name,
            content_type: file.type,
          });
        };
        reader.readAsBinaryString(file);
      });
    });
        
    const formattedAttachmentsArray = await Promise.all(formattedAttachments);
  
    try {
      await sendEmail(toEmails, ccEmails, bccEmails, subject, emailContent.innerHTML, formattedAttachmentsArray);
      toast.success("Email successfully sent!");
      onClose();
    } catch (error) {
      console.log("Error sending email:", error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setAttachments(prevAttachments => [...prevAttachments, ...Array.from(e.target.files)])
    }
  }

  const removeAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index))
  }

  const toggleCcBcc = () => {
    setShowCcBcc(prev => !prev)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.entries(suggestionsRef.current).forEach(([type, ref]) => {
        if (ref && !ref.contains(event.target)) {
          setSuggestions(prev => ({ ...prev, [type]: [] }))
        }
      })
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  const renderEmailFields = (type, value, label) => (
    <div className="grid grid-cols-1 sm:grid-cols-4 items-start gap-2 sm:gap-4">
      <Label htmlFor={type} className="sm:text-right mt-3">
        {label}:
      </Label>
      <div className="col-span-1 sm:col-span-3 space-y-2">
        <div className="flex items-center gap-2">
          <div className="relative flex-grow">
            <Input
              id={type}
              ref={el => inputRefs.current[type] = el}
              value={value}
              onChange={(e) => handleEmailChange(type, e.target.value, e.target.selectionStart)}
              onKeyUp={(e) => setCursorPosition(prev => ({ ...prev, [type]: e.target.selectionStart }))}
              onClick={(e) => setCursorPosition(prev => ({ ...prev, [type]: e.target.selectionStart }))}
              className="flex-grow"
              placeholder={`Enter email(s)`}
            />
            {suggestions[type].length > 0 && (
              <ul 
                ref={el => suggestionsRef.current[type] = el}
                className="absolute z-10 w-full bg-white border rounded-md shadow-lg max-h-60 overflow-auto"
              >
                {suggestions[type].map((suggestion, index) => (
                  <li 
                    key={index}
                    className="px-4 py-2 hover:bg-muted cursor-pointer"
                    onClick={() => handleSuggestionClick(type, suggestion)}
                  >
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
          {type === 'to' && (
            <Button
              type="button"
              variant="outline"
              size="icon"
              onClick={toggleCcBcc}
            >
              {showCcBcc ? (
                <>
                  <Minus className="h-4 w-4" />
                  <span className="sr-only">Hide CC and BCC</span>
                </>
              ) : (
                <>
                  <Plus className="h-4 w-4" />
                  <span className="sr-only">Show CC and BCC</span>
                </>
              )}
            </Button>
          )}
        </div>
      </div>
    </div>
  )

  //const formattedEmail = useMemo(() => emailContent.replace(/\n/g, '<br>'), [emailContent]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px] w-[95vw] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Send Email</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {renderEmailFields('to', to, 'To')}
          {showCcBcc && (
            <>
              {renderEmailFields('cc', cc, 'CC')}
              {renderEmailFields('bcc', bcc, 'BCC')}
            </>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-4 items-center gap-2 sm:gap-4">
            <Label htmlFor="subject" className="sm:text-right">
              Subject:
            </Label>
            <Input
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="col-span-1 sm:col-span-3"
              placeholder="Enter subject"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-4 items-start gap-2 sm:gap-4">
            <Label htmlFor="content" className="sm:text-right sm:mt-2">
              Content:
            </Label>
            <div className="sm:col-span-3 w-full">
              <div className="text-sm mt-2"
                dangerouslySetInnerHTML={{ __html: emailContent?.innerHTML || "" }}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-4 items-start gap-2 sm:gap-4">
            <Label htmlFor="attachments" className="sm:text-right sm:mt-2">
              Attachments:
            </Label>
            <div className="col-span-1 sm:col-span-3">
              <div className="flex items-center gap-2">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => document.getElementById('attachments').click()}
                >
                  Choose Files
                </Button>
                <Input
                  id="attachments"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="hidden"
                />
                {attachments.length > 0 && (
                  <span>{attachments.length} file(s) selected</span>
                )}
              </div>
              {attachments.length > 0 && (
                <ul className="list-disc pl-5 space-y-1 mt-2">
                  {attachments.map((file, index) => (
                    <li key={index} className="flex items-center justify-between text-sm">
                      <span className="truncate mr-2">{file.name}</span>
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => removeAttachment(index)}
                        className="h-6 w-6 p-0 text-destructive hover:text-destructive/90"
                      >
                        <X className="h-4 w-4" />
                        <span className="sr-only">Remove {file.name}</span>
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className="text-red-600 text-sm mt-4">
            {errorMessage}
          </div>
        )}
        <DialogFooter>
          <Button type="submit" onClick={handleSend}>
            Send
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}