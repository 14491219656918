import React, { useState, useContext } from "react";
import { cn } from "utils/utils";
import { ScrollArea } from "components/ui/scroll-area";
import { Badge } from "components/ui/badge";
import { CheckCircle2, ThumbsDown, ThumbsUp, XCircle, XIcon } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { Button } from "components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/ui/popover";
import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
  DrawerClose,
} from "components/ui/drawer";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { SnoozeOutlined } from "@mui/icons-material";
import { DataContext } from 'contexts/DataContext';
import { useMediaQuery } from "@react-hook/media-query";
import { toast } from 'sonner'; // Add this import
import { formatISO, addDays } from 'date-fns';
import ResponsiveModal from "./responsive-modal";
import { ReminderCreate } from "components/reminders-component/reminder-create";

function getBadgeVariantFromLabel(label) {
  if (["voice", "text"].includes(label.toLowerCase())) {
    return "default";
  }

  if (["personal"].includes(label.toLowerCase())) {
    return "outline";
  }

  return "outline";
}

export function ActionItems({ item }) {
  const { completeInsight, logInsightFeedback, rejectInsight, snoozeInsight, removeSnoozeInsight, } = useContext(DataContext);
  const [thumbsUpFeedback, setThumbsUpFeedback] = useState("");
  const [thumbsDownFeedback, setThumbsDownFeedback] = useState("");
  const [isThumbsUpOpen, setIsThumbsUpOpen] = useState(false);
  const [isThumbsDownOpen, setIsThumbsDownOpen] = useState(false);
  const [snoozeOneWeek, setSnoozeOneWeek] = useState(false);
  const [snoozeOneMonth, setSnoozeOneMonth] = useState(false);
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleFeedback = (isPositive, writtenFeedback = "") => {
    logInsightFeedback(item.task_id, isPositive, writtenFeedback);
  };

  const handleClose = () => {
    setIsThumbsUpOpen(false);
    setIsThumbsDownOpen(false);
  };

  const handleSnoozeOneWeekChange = (checked) => {
    setSnoozeOneWeek(checked);
    
    const snoozeDate = formatISO(addDays(new Date(), 7)); 
    
    if (checked) {
      snoozeInsight(item.task_id, snoozeDate); 
      setSnoozeOneMonth(false);
    }
  };
  
  const handleSnoozeOneMonthChange = (checked) => {
    setSnoozeOneMonth(checked);
    
    const snoozeDate = formatISO(addDays(new Date(), 30)); 
    
    if (checked) {
      snoozeInsight(item.task_id, snoozeDate); 
      setSnoozeOneWeek(false);
    }
  };

  const handleCompleteClick = () => {
    completeInsight(item.task_id);
    toast("Insight has been marked as completed", {
      action: {
        label: "Close",
        onClick: () => console.log("Close"),
      },
    });
  };

  const handleRejectClick = () => {
    rejectInsight(item.task_id);
    toast("Insight has been marked as not relevant", {
      action: {
        label: "Close",
        onClick: () => console.log("Close"),
      },
    });
  };

  const handleSnoozeClick = (duration) => {
    if (duration === 'week') {
      handleSnoozeOneWeekChange(true);
    } else if (duration === 'month') {
      handleSnoozeOneMonthChange(true);
    }
    toast(`Insight has been snoozed for ${duration === 'week' ? '1 week' : '1 month'}`, {
      action: {
        label: "Close",
        onClick: () => console.log("Close")
      },
    });
  };

  const handleRemoveSnoozeClick = () => {
    removeSnoozeInsight(item.task_id);
    toast("Snooze has been removed", {
      action: {
        label: "Close",
        onClick: () => console.log("Close"),
      },
    });
  };

  return (
    <div className="flex items-center justify-between mt-2 gap-2">
      <div className="flex items-center gap-1">
        <Badge variant="outline">{item?.status === "accepted" ? "Completed" : "Incomplete"}</Badge>
      </div>
      <div className="flex items-center gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <DialogClose className="w-full">
                <button className="flex items-center justify-center w-10 h-10" onClick={handleCompleteClick}>
                  {item.status == "accepted" ?
                    (<CheckCircle2 className="h-5 w-5 text-green-500" />) :
                    (<CheckCircle2 className="h-5 w-5" />)
                  }
                </button>
              </DialogClose>
            </TooltipTrigger>
            <TooltipContent>Mark as Completed</TooltipContent>
          </Tooltip>
          {item.status !== "accepted" && (
            <Tooltip>
              <TooltipTrigger asChild>
                <Popover open={isThumbsDownOpen} onOpenChange={setIsThumbsDownOpen}>
                  <PopoverTrigger asChild>
                    <button className="flex items-center justify-center w-10 h-10">
                      <XCircle className="h-5 w-5" />
                    </button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <div className="grid w-full gap-4">
                      <Label>Please provide your feedback for marking this as not relevant.</Label>
                      <Textarea
                        placeholder="Type your message here."
                        value={thumbsDownFeedback}
                        onChange={(e) => setThumbsDownFeedback(e.target.value)}
                      />
                      <div className="flex justify-end p-4 pt-0">
                        <DialogClose>
                          <DrawerClose>
                            <Button 
                              size="sm" 
                              className="ml-auto" 
                              onClick={() => {
                                handleRejectClick();
                                handleFeedback(false, thumbsDownFeedback);
                                handleClose();
                                toast("Marked as not relevant", {
                                  description: "Thank you for your feedback!",
                                  action: {
                                    label: "Close",
                                    onClick: () => console.log("Close"),
                                  },
                                });
                            }}>Submit</Button>
                          </DrawerClose>
                        </DialogClose>
                      </div>
                    </div>
                  </PopoverContent>
                </Popover>
              </TooltipTrigger>
              <TooltipContent>Mark as not Relevant</TooltipContent>
            </Tooltip>
          )}
          {/*
          <DropdownMenu>
            <Tooltip>
              <TooltipTrigger asChild>
                <DropdownMenuTrigger asChild>
                  <button className="flex items-center justify-center w-10 h-10">
                    <SnoozeOutlined className="h-5 w-5" />
                  </button>
                </DropdownMenuTrigger>
              </TooltipTrigger>
              <TooltipContent>{item.status === "snoozed" ? "Remove Snooze" : "Set to Snooze"}</TooltipContent>
            </Tooltip>
            <DropdownMenuContent className="w-56">
              {item.status === "snoozed" ? (
                <DialogClose className="w-full">
                  <DropdownMenuCheckboxItem
                    onClick={handleRemoveSnoozeClick}
                  >
                    Remove Snooze
                  </DropdownMenuCheckboxItem>
                </DialogClose>
              ) : (
                <>
                  <DialogClose className="w-full">
                    <DropdownMenuCheckboxItem
                      checked={snoozeOneWeek}
                      onCheckedChange={() => handleSnoozeClick('week')}
                    >
                      Snooze for 1 Week
                    </DropdownMenuCheckboxItem>
                  </DialogClose>
                  <DialogClose className="w-full">
                    <DropdownMenuCheckboxItem
                      checked={snoozeOneMonth}
                      onCheckedChange={() => handleSnoozeClick('month')}
                    >
                      Snooze for 1 Month
                    </DropdownMenuCheckboxItem>
                  </DialogClose>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
          */}
        </TooltipProvider>
      </div>
      <div className="flex items-center gap-1">
      <TooltipProvider>
        <ResponsiveModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          trigger={
            <Button 
              variant="outline" 
              className="flex items-center gap-2"
              onClick={() => setIsOpen(true)}
            >
              Add Reminder
            </Button>
          }
          title="Create a New Reminder"
          description={
            <ReminderCreate 
              customerId={item.custcd}
              handleClose={() => setIsOpen(false)}
              setIsOpen={setIsOpen}
            />
          }
        />
      </TooltipProvider>
        {/* SET TO SNOOZE A LINE ABOVE HERE
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Popover open={isThumbsUpOpen} onOpenChange={setIsThumbsUpOpen}>
                <PopoverTrigger asChild>
                  <button
                    aria-label="Thumb Up"
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => handleFeedback(true)}
                  >
                    <ThumbsUp
                      className="w-5 h-5 mr-1"
                      fill={item.feedback === true ? "currentColor" : "none"}
                    />
                  </button>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="grid w-full gap-4">
                    <Label>Please provide your Feedback.</Label>
                    <Textarea
                      placeholder="Type your message here."
                      value={thumbsUpFeedback}
                      onChange={(e) => setThumbsUpFeedback(e.target.value)}
                    />
                    <div className="flex justify-end p-4 pt-0">
                      <Button size="sm" className="ml-auto" variant="outline" onClick={handleClose}>Close</Button>
                      <Button size="sm" className="ml-2" onClick={() => {
                        handleFeedback(true, thumbsUpFeedback);
                        handleClose();
                        toast("Feedback submitted", {
                          description: "Thank you for your feedback!",
                          action: {
                            label: "Close",
                            onClick: () => console.log("Close"),
                          },
                        });
                      }}>Send Feedback</Button>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </TooltipTrigger>
            <TooltipContent>Thumbs Up</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <Popover open={isThumbsDownOpen} onOpenChange={setIsThumbsDownOpen}>
                <PopoverTrigger asChild>
                  <button
                    aria-label="Thumb Down"
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => handleFeedback(false)}
                  >
                    <ThumbsDown
                      className="w-5 h-5 mr-1"
                      fill={item.feedback === false ? "currentColor" : "none"}
                    />
                  </button>
                </PopoverTrigger>
                <PopoverContent>
                  <div className="grid w-full gap-4">
                    <Label>Please provide your Feedback.</Label>
                    <Textarea
                      placeholder="Type your message here."
                      value={thumbsDownFeedback}
                      onChange={(e) => setThumbsDownFeedback(e.target.value)}
                    />
                    <div className="flex justify-end p-4 pt-0">
                      <Button size="sm" className="ml-auto" variant="outline" onClick={handleClose}>Close</Button>
                      <Button size="sm" className="ml-2" onClick={() => {
                        handleFeedback(false, thumbsDownFeedback);
                        handleClose();
                        toast("Feedback submitted", {
                          description: "Thank you for your feedback!",
                          action: {
                            label: "Close",
                            onClick: () => console.log("Close"),
                          },
                        });
                      }}>Send Feedback</Button>
                    </div>
                  </div>
                </PopoverContent>
              </Popover>
            </TooltipTrigger>
            <TooltipContent>Thumbs Down</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        */}
      </div>
    </div>
  );
}

export function CompanyInsightsList({ items }) {
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);

  if (!items) {
    return <div>No items to display</div>;
  }

  const renderItemContent = (item) => (
    <>
      <div className="flex w-full flex-col gap-1">
        <div className="flex items-center">
          <div className="flex items-center gap-2">
            <div className="font-semibold">{item.cosailor_task[0]}</div>
            {!item.read && (
              <span className="flex h-2 w-2 rounded-full bg-blue-600" />
            )}
          </div>
          <div
            className={cn(
              "ml-auto text-xs",
              item.selected ? "text-foreground" : "text-muted-foreground"
            )}
          >
            {new Date(item.compute_time).toLocaleDateString()}
          </div>
        </div>
        <div className="text-xs font-medium">{item.subject}</div>
      </div>
      <div className="line-clamp-2 text-xs text-muted-foreground">
        {item.cosailor_task[1]}
      </div>
    </>
  );

  return (
    <TooltipProvider>
      <div className="flex flex-col gap-2 pt-0">
        {items.map((item) => (
          <React.Fragment key={item.task_id}>
            {isMobile ? (
              <Drawer>
                <DrawerTrigger asChild>
                  <button
                    className={cn(
                      "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
                      item.selected && "bg-muted"
                    )}
                  >
                    {renderItemContent(item)}
                  </button>
                </DrawerTrigger>
                <DrawerContent>
                  <DrawerHeader className="max-w-screen-lg mx-auto">
                    <div className="flex justify-between items-center w-full">
                      <DrawerTitle>{item.cosailor_task[0]}</DrawerTitle>
                      <DrawerClose asChild>
                        <button aria-label="Close" className="text-gray-500 hover:text-gray-700">
                          <XIcon className="w-4 h-4" />
                        </button>
                      </DrawerClose>
                    </div>
                    <DrawerDescription className="text-left">
                      <div
                        className={cn(
                          "ml-auto text-xs",
                          item.selected
                            ? "text-foreground"
                            : "text-muted-foreground"
                        )}
                      >
                        {new Date(item.compute_time).toLocaleDateString()}
                      </div>
                      <div className="py-3 text-left">
                        <ScrollArea className="w-full h-[300px] p-10 rounded-md border">
                          <>
                            <div>{item.cosailor_task[1]}</div><br/>
                            {Array.isArray(item.nuclia_output) && item.nuclia_output.map((output, index) => (
                              <div key={index}>
                                • <strong>{output.point}</strong> - {output.explanation}<br/><br/>
                              </div>
                            ))}
                          </>
                        </ScrollArea>
                      </div>
                      <ActionItems item={item} />
                    </DrawerDescription>
                  </DrawerHeader>
                </DrawerContent>
              </Drawer>
            ) : (
              <Dialog>
                <DialogTrigger asChild>
                  <button
                    className={cn(
                      "flex flex-col items-start gap-2 rounded-lg border p-3 text-left text-sm transition-all hover:bg-accent",
                      item.selected && "bg-muted"
                    )}
                  >
                    {renderItemContent(item)}
                  </button>
                </DialogTrigger>
                <DialogContent>
                  <DialogHeader className="max-w-screen-lg mx-auto">
                    <div className="flex justify-between items-center w-full">
                      <DialogTitle>{item.cosailor_task[0]}</DialogTitle>
                    </div>
                    <DialogDescription className="text-left">
                      <div
                        className={cn(
                          "ml-auto text-xs",
                          item.selected
                            ? "text-foreground"
                            : "text-muted-foreground"
                        )}
                      >
                        {new Date(item.compute_time).toLocaleDateString()}
                      </div>
                      <div className="flex items-center gap-2 mt-2">
                        <Button 
                          variant="ghost" 
                          className="text-sm"
                          onClick={() => setIsReminderModalOpen(true)}
                        >
                          Set Reminder
                        </Button>
                        
                        <ResponsiveModal
                          isOpen={isReminderModalOpen}
                          setIsOpen={setIsReminderModalOpen}
                          title="Create a New Reminder"
                          description={
                            <ReminderCreate 
                              customerId={item.custcd}
                              handleClose={() => setIsReminderModalOpen(false)}
                              setIsOpen={setIsReminderModalOpen}
                            />
                          }
                        />
                      </div>
                      <div className="py-3 text-left">
                        <ScrollArea className="w-full p-4 h-[300px] rounded-md border">
                          <>
                            <div>{item.cosailor_task[1]}</div><br/>
                            {Array.isArray(item.nuclia_output) && item.nuclia_output.map((output, index) => (
                              <div key={index}>
                                • <strong>{output.point}</strong> - {output.explanation}<br/><br/>
                              </div>
                            ))}
                          </>
                        </ScrollArea>
                      </div>
                      <ActionItems item={item} />
                    </DialogDescription>
                  </DialogHeader>
                </DialogContent>
              </Dialog>
            )}
          </React.Fragment>
        ))}
      </div>
    </TooltipProvider>
  );
}