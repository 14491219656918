import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AppContext } from 'contexts/AppContext';
import { getAccessToken } from 'utils/authUtils'

const AuthGuard = ({ children }) => {
  const { testing } = useContext(AppContext);
  if (testing) return children

  const accessToken = getAccessToken(false);
  if (accessToken && (accessToken!="eyJraWQiOiJnRXZwSWFxb1ZjNjhCYjFIZVRPTlRlRXdLYW5VWmNVbmtoZW1CaWJJSGtvIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVyOHV3Mmc3bUFXbXE3RDJwNyIsIm5hbWUiOiJDcmlzdGluIENvbm5lcm5leSIsImVtYWlsIjoiQ3Jpc3Rpbi5Db25uZXJuZXlAc3JzZGlzdHJpYnV0aW9uLmNvbSIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9zcnMub2t0YS5jb20vb2F1dGgyL2F1c3Zvamd2OXh2ekNTRGlYMnA3IiwiYXVkIjoiMG9hdjZ5YWQwN1Jrc2U3NVoycDciLCJpYXQiOjE3MzE2OTI3OTMsImV4cCI6MTczMTY5NjM5MywianRpIjoiSUQuc0h6VDVwYkpNYjVlcXc4eWNGMDgwVktWOGJPZkk3d0txdVRrRVVsVEdlYyIsImFtciI6WyJzbXMiLCJtZmEiLCJtY2EiLCJwd2QiXSwiaWRwIjoiMDBvMTZ6andlY0NGdTdwVWoycDciLCJub25jZSI6ImJxMWZzNzZUQ2xjQ1ZDNXpIU1RoVk84VGxuSmJXRjRWOFFnenQ3Q09GYmxaY3NFTTRONFlFeVFxTk1XVDNxWGciLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJDQzEwNjQ3MkBtbWhmZ2IuY29tIiwiYXV0aF90aW1lIjoxNzMxNjg4Njc0LCJhdF9oYXNoIjoiQkNaX1RUblhYUXI3WEV6eXJKQkFQUSJ9.rttI1oz_LwFnsLe2w7Ad3ADJFK4icFpRDPdxXjZatUVUR5ma6yPk3AAnRjJLLKZOvBvh3vQ01bCoCHFRsuoJcDC4jcvPxmahJupyC_VZDv9DtPf5TlT8j4wvUQj-_7pCIz5aYDABZoDheXWUEnCG09bPQCUU-cssl16lS5_lsM0s42IccY9pvX-mkDlfE6YI1jf1_Um5LwIzPJ51z5G41hbpBDlXp_sv3xRJc5a-gaIkYB6BFiiKTaSTS-AFPRirv7DJIv3i6J2IwSJyyfcTj-hPGAHhW-kl1QScp9Z0pcoG7WKOQpQIumSB7WCbn3HqbqJr_ZXLhfyYsCTConskGA")) {
    return children;
  }

  const { authState, oktaAuth } = useOktaAuth();

  const isAuthenticated = useMemo(() => authState?.isAuthenticated, [authState]);
  const isLoading = useMemo(() => !authState, [authState]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (!isAuthenticated && !isLoading && !currentUrl.includes('/login/callback')) {
      oktaAuth.signInWithRedirect();
    }
  }, [isAuthenticated, isLoading, oktaAuth]);

  if (isLoading || !isAuthenticated) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(AuthGuard);
