import { Building2 } from "lucide-react";
import { Card, CardContent } from "components/ui/card";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";

export default function CustomerInfoBox({ customerId = "APX512", customerName = "APEX EXTERIORS LLC" }) {
  return (
    <div className="sticky top-0 mb-2 z-50 bg-white">
      <TooltipProvider>
        <Card className="bg-secondary/10">
          <CardContent className="p-3 flex items-center space-x-3">
            <Building2 className="h-5 w-5 m-2 text-primary flex-shrink-0" aria-hidden="true" />
            <div className="flex-grow min-w-0">
              <Tooltip>
                <TooltipTrigger asChild>
                  <p className="font-medium text-primary truncate" aria-label={`Customer: ${customerName}`}>
                    {customerName}
                  </p>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{customerName}</p>
                </TooltipContent>
              </Tooltip>
              <p className="text-xs text-muted-foreground truncate">Customer: {customerId}</p>
            </div>
          </CardContent>
        </Card>
      </TooltipProvider>
    </div>
  );
}
