'use client'

import { useState } from 'react'
import { Button } from "components/ui/button"
import { Calendar } from "components/ui/calendar"
import { Input } from "components/ui/input"
import { Label } from "components/ui/label"
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select"
import { Switch } from "components/ui/switch"
import { Textarea } from "components/ui/textarea"
import { format } from "date-fns"
import { CalendarIcon, Clock, MapPin, Users, X } from 'lucide-react'
import { toast } from 'sonner'
import { Dialog, DialogClose } from "components/ui/dialog"

export default function CalendarEvent({ customerName, customerId, onClose }) {
  const [attendees, setAttendees] = useState([])
  const [newAttendee, setNewAttendee] = useState('')
  const [selectedDate, setSelectedDate] = useState()
  const [startTime, setStartTime] = useState('12:00')
  const [endTime, setEndTime] = useState('12:30')
  const [title, setTitle] = useState('')
  const [isAllDay, setIsAllDay] = useState(false)
  const [location, setLocation] = useState('')

  const handleAddAttendee = (e) => {
    e.preventDefault()
    if (newAttendee && !attendees.includes(newAttendee)) {
      setAttendees([...attendees, newAttendee])
      setNewAttendee('')
    }
  }

  const removeAttendee = (attendeeToRemove) => {
    setAttendees(attendees.filter(attendee => attendee !== attendeeToRemove))
  }

  const handleSubmit = () => {
    // event creation logic
    
    // Show success notification
    toast.success("Calendar Event Created")
    
    // Close the dialog using the onClose prop
    if (typeof onClose === 'function') {
      onClose();
    }
  }

  return (
    <div className="w-full max-w-5xl mx-auto relative">
      <div className="">
        <div className="flex flex-col mb-6">
          <h2 className="text-lg sm:text-xl font-semibold">
            Add Calendar Event
          </h2>
          <span className="text-base sm:text-md text-slate-500 dark:text-slate-500 font-light">
            {customerName || customerId}
          </span>
        </div>
        <div className="space-y-4 sm:space-y-6">
          {/* Title Input */}
          <div className="space-y-2">
            <Input
              placeholder="Add title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="text-base sm:text-lg font-semibold w-full"
            />
          </div>

          {/* Attendees */}
          <div className="space-y-2">
            <form onSubmit={handleAddAttendee} className="w-full">
              <Input
                placeholder="Add attendees"
                value={newAttendee}
                onChange={(e) => setNewAttendee(e.target.value)}
                type="email"
                className="w-full"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddAttendee(e);
                  }
                }}
              />
            </form>
            <div className="flex flex-wrap gap-2 mt-2">
              {attendees.map((attendee) => (
                <div
                  key={attendee}
                  className="flex items-center gap-1 bg-secondary px-2 py-1 rounded-full text-xs sm:text-sm"
                >
                  <span>{attendee}</span>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-4 w-4"
                    onClick={() => removeAttendee(attendee)}
                  >
                    <X className="h-3 w-3" />
                    <span className="sr-only">Remove attendee</span>
                  </Button>
                </div>
              ))}
            </div>
          </div>

          {/* Date and Time Selection */}
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>Date</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-full justify-start text-left font-normal"
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {selectedDate ? format(selectedDate, "PPP") : "Select date"}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={selectedDate}
                    onSelect={setSelectedDate}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between space-y-2 sm:space-y-0">
              <Label>Time</Label>
              <div className="flex items-center space-x-2">
                <Switch
                  id="all-day"
                  checked={isAllDay}
                  onCheckedChange={setIsAllDay}
                />
                <Label htmlFor="all-day">All day</Label>
              </div>
            </div>
            {/* Time selection area - maintains fixed width when "All day" is toggled */}
            <div className="h-10">
              {!isAllDay ? (
                <div className="flex flex-col sm:flex-row gap-2 items-start sm:items-center">
                  <Select value={startTime} onValueChange={setStartTime}>
                    <SelectTrigger className="w-full sm:w-[140px]">
                      <SelectValue placeholder="Start time" />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from({ length: 24 }).map((_, i) => (
                        <SelectItem key={i} value={`${i.toString().padStart(2, '0')}:00`}>
                          {`${i.toString().padStart(2, '0')}:00`}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <span className="hidden sm:inline">to</span>
                  <Select value={endTime} onValueChange={setEndTime}>
                    <SelectTrigger className="w-full sm:w-[140px]">
                      <SelectValue placeholder="End time" />
                    </SelectTrigger>
                    <SelectContent>
                      {Array.from({ length: 24 }).map((_, i) => (
                        <SelectItem key={i} value={`${i.toString().padStart(2, '0')}:30`}>
                          {`${i.toString().padStart(2, '0')}:30`}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              ) : (
                <div className="flex gap-2 items-center">
                  <div className="w-full sm:w-[140px] h-10" />
                  <span className="hidden sm:inline invisible">to</span>
                  <div className="w-full sm:w-[140px] h-10" />
                </div>
              )}
            </div>
          </div>

          {/* Additional Options */}
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <Clock className="h-4 w-4 shrink-0" />
              <Select defaultValue="does-not-repeat">
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Does not repeat" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="does-not-repeat">Does not repeat</SelectItem>
                  <SelectItem value="daily">Daily</SelectItem>
                  <SelectItem value="weekly">Weekly</SelectItem>
                  <SelectItem value="monthly">Monthly</SelectItem>
                </SelectContent>
              </Select>
            </div>
            {/*
            <div className="flex items-center gap-2">
              <Users className="h-4 w-4 shrink-0" />
              <Button variant="outline" className="w-full">Add channel</Button>
            </div>
            <div className="flex items-center gap-2">
              <MapPin className="h-4 w-4 shrink-0" />
              <Input
                placeholder="Add location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="w-full"
              />
            </div>
            */}
          </div>

          {/* Meeting Details */}
          <div className="space-y-2">
            <Label htmlFor="meeting-details">Meeting details</Label>
            <Textarea
              id="meeting-details"
              placeholder="Type details for this new meeting"
              className="min-h-[100px] w-full"
            />
          </div>

          {/* Submit Button */}
          <DialogClose asChild>
            <Button className="w-full" onClick={handleSubmit}>Create Event</Button>
          </DialogClose>
        </div>
      </div>
    </div>
  )
}