export const getAccessToken = (testing) => {
    if (testing) return "eyJraWQiOiJnRXZwSWFxb1ZjNjhCYjFIZVRPTlRlRXdLYW5VWmNVbmtoZW1CaWJJSGtvIiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiIwMHVyOHV3Mmc3bUFXbXE3RDJwNyIsIm5hbWUiOiJDcmlzdGluIENvbm5lcm5leSIsImVtYWlsIjoiQ3Jpc3Rpbi5Db25uZXJuZXlAc3JzZGlzdHJpYnV0aW9uLmNvbSIsInZlciI6MSwiaXNzIjoiaHR0cHM6Ly9zcnMub2t0YS5jb20vb2F1dGgyL2F1c3Zvamd2OXh2ekNTRGlYMnA3IiwiYXVkIjoiMG9hdjZ5YWQwN1Jrc2U3NVoycDciLCJpYXQiOjE3MzE2OTI3OTMsImV4cCI6MTczMTY5NjM5MywianRpIjoiSUQuc0h6VDVwYkpNYjVlcXc4eWNGMDgwVktWOGJPZkk3d0txdVRrRVVsVEdlYyIsImFtciI6WyJzbXMiLCJtZmEiLCJtY2EiLCJwd2QiXSwiaWRwIjoiMDBvMTZ6andlY0NGdTdwVWoycDciLCJub25jZSI6ImJxMWZzNzZUQ2xjQ1ZDNXpIU1RoVk84VGxuSmJXRjRWOFFnenQ3Q09GYmxaY3NFTTRONFlFeVFxTk1XVDNxWGciLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJDQzEwNjQ3MkBtbWhmZ2IuY29tIiwiYXV0aF90aW1lIjoxNzMxNjg4Njc0LCJhdF9oYXNoIjoiQkNaX1RUblhYUXI3WEV6eXJKQkFQUSJ9.rttI1oz_LwFnsLe2w7Ad3ADJFK4icFpRDPdxXjZatUVUR5ma6yPk3AAnRjJLLKZOvBvh3vQ01bCoCHFRsuoJcDC4jcvPxmahJupyC_VZDv9DtPf5TlT8j4wvUQj-_7pCIz5aYDABZoDheXWUEnCG09bPQCUU-cssl16lS5_lsM0s42IccY9pvX-mkDlfE6YI1jf1_Um5LwIzPJ51z5G41hbpBDlXp_sv3xRJc5a-gaIkYB6BFiiKTaSTS-AFPRirv7DJIv3i6J2IwSJyyfcTj-hPGAHhW-kl1QScp9Z0pcoG7WKOQpQIumSB7WCbn3HqbqJr_ZXLhfyYsCTConskGA"
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return oktaTokenStorage?.idToken?.idToken;
};

export const getLoggedInUser = async (testing) => {
  if (testing) return { userId: 'CC106472', firstName: 'Test', lastName: 'User' };

  const retryDelay = 500;
  const maxRetries = 5;

  // Helper function to wait for a specified duration
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Function to attempt fetching user data with retries
  const fetchFromOktaTokenStorage = async (retries) => {
    const oktaTokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

    // Ensure tokens are ready
    if (!oktaTokenStorage || !oktaTokenStorage.idToken) {
      if (retries > 0) {
        await delay(retryDelay); // Wait before retrying
        return fetchFromOktaTokenStorage(retries - 1); // Retry fetching
      }
      return null; // Return null if all retries are exhausted
    }

    const preferredUsername = oktaTokenStorage.idToken.claims?.preferred_username;
    const name = oktaTokenStorage.idToken.claims?.name;

    if (preferredUsername && name) {
      const [firstPart] = preferredUsername.split('@');
      const [firstName, lastName] = name.split(' ');
      return { userId: firstPart, firstName: firstName || '', lastName: lastName || '' };
    }

    return null;
  };

  return await fetchFromOktaTokenStorage(maxRetries);
};
